import React, { useState, useEffect } from "react";
import NavBar from "../collections/NavBar";
import ArticlesTable from "./ArticlesTable";
import SearchTable from "./SearchTable";
import { getReportData, getUserProfile } from "../../services/request";

const ReportsContent = () => {
  const [articles, setArticles] = useState([]);
  const [emptySearches, setEmptySearches] = useState([]);
  const [userProfile, setUserProfile] = useState("");
  const [loading, setLoading] = useState(false);

  const getReport = async () => {
    setLoading(true);
    let alpsToken = localStorage.getItem("alpsToken");
    let info = await getUserProfile(alpsToken);
    setUserProfile(info);
    let data = await getReportData(alpsToken);
    console.log(data);
    setArticles(data.topArticles);
    setEmptySearches(data.emptySearchQueries);
    setLoading(false);
  };
  useEffect(() => {
    const alpsToken = localStorage.getItem("alpsToken");
    if (alpsToken) {
      getReport();
    } else {
      window.location.href = "/login";
    }
  }, []);
  return (
    <>
      <NavBar active="Reports" />
      <div
        className={`w-full cflexss gap-[30px] h-full font-inter pb-[80px] px-[180px] mt-[250px]`}
      >
        <ArticlesTable
          articles={articles}
          userProfile={userProfile}
          loading={loading}
        />
        <SearchTable
          emptySearches={emptySearches}
          userProfile={userProfile}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ReportsContent;
