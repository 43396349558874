import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader/Loader";
import { userRegistration } from "../services/request";
import { EyeOffOutline, EyeOutline } from "heroicons-react";
import TermsPrivacy from "./collections/TermsPrivacy";

const Register = () => {
  const [registerDetails, setRegisterDetails] = useState({
    email: "",
    password: "",
  });
  const [changing, setChanging] = useState(false);
  const [err, setErr] = useState(false);
  const [nextStep, setNextStep] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showP, setShowP] = useState(false);
  const [modal, setModal] = useState(null);
  const [popUp, setPopUp] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setRegisterDetails({ ...registerDetails, [name]: value });
    setChanging(!changing);
  };

  useEffect(() => {
    if (
      registerDetails["password"] &&
      registerDetails["password"].length >= 12
    ) {
      setErr(false);
    } else if (registerDetails["password"].length === 0) {
      setErr(false);
    } else if (registerDetails["password"].length < 12) {
      setErr(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changing]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (registerDetails.email) {
      setNextStep(true);
    }
    if (
      registerDetails.email &&
      registerDetails.password &&
      registerDetails["password"].length >= 12
    ) {
      setLoading(true);
      await userRegistration(registerDetails, setPopUp, setModal);
      setLoading(false);
    }
  };
  return (
    <>
      {popUp && modal}
      <div className="w-full h-[100vh] cflexbm text-[16px] pt-[32px] pb-[50px] font-inter">
        <Link to="/" className="w-[85px] h-[85px] flexmm shadow-sm">
          <img src="/logoAlps.svg" className="w-full h-full" alt="logo" />
        </Link>
        <form
          className="m-[10px] p-[50px] w-[400px] cflexsm flex-shrink pt-[64px] pb-[70px]"
          onSubmit={handleSubmit}
        >
          <p className="text-[29.63px] font-[700] leading-[48px] pb-[24px] text-[#2D333A]">
            Create your account
          </p>
          <input
            type="email"
            name="email"
            value={registerDetails.email}
            onChange={handleChange}
            placeholder="Email address"
            className=" border-[2px] shadow-sm w-full p-[10px] mb-[15px] rounded-[8px] outline-none"
          />
          {nextStep && (
            <>
              <div className="w-full cflexms">
                <div className="border-[2px] shadow-sm flexmm gap-[10px] w-full p-[10px] mb-[15px] rounded-[8px]">
                  <input
                    type={showP ? "text" : "password"}
                    name="password"
                    value={registerDetails.password}
                    onChange={handleChange}
                    placeholder="Password"
                    className="border-none w-full outline-none"
                  />
                  {showP ? (
                    <EyeOffOutline
                      size="20px"
                      onClick={() => setShowP(!showP)}
                      className="cursor-pointer"
                    />
                  ) : (
                    <EyeOutline
                      size="20px"
                      onClick={() => setShowP(!showP)}
                      className="cursor-pointer"
                    />
                  )}
                </div>
                {err && (
                  <div className="w-full rounded-[4px] text-[14px] p-[10px] mb-[15px] border-[2px]">
                    <p>Your password must contain:</p>
                    <ul
                      className="px-[20px] font-[400] leading-[22px]"
                      style={{ listStyleType: "unset" }}
                    >
                      <li>At least 12 characters</li>
                    </ul>
                  </div>
                )}
              </div>
            </>
          )}
          <button
            type="submit"
            className="text-[16px] mb-[24px] text-white font-[600] border-none px-[16px] py-[8px] flexmm rounded-[8px] w-full bg-buttonColor"
          >
            {loading ? <Loader /> : <p className="leading-[22px]">Continue</p>}
          </button>
          <div className="relative pb-[30px] border-b-[2px] mb-[24px] w-full flexmm">
            <p>
              Already have an account?{" "}
              <Link to="/login" className="underline">
                {" "}
                Log in
              </Link>
            </p>
            <p className="absolute text-center bottom-[-15px] text-[#707070] bg-white p-[5px]">
              OR
            </p>
          </div>
          <div className="w-full rounded-[4px] border-[2px] flexmm gap-[12px] px-[16px] py-[12px] cursor-pointer">
            <img
              src="/google-icn.svg"
              alt="google-icon"
              className="w-[24px] h-[24px]"
            />
            <p>Continue with Google</p>
          </div>
        </form>
        <TermsPrivacy />
      </div>
    </>
  );
};

export default Register;
