import { useState } from "react";
import { GlobeAltOutline } from "heroicons-react";
import PaystackPayment from "./PaystackPayment";
import { initializePayment } from "../services/request";
import Loader from "./Loader/Loader";

const AlpsPro = ({ setPro, email, publicInfo }) => {
  // require('dotenv').config()
  // const PAYSTACK_MONTHLY_NAIRA = "PLN_z5kvvu5em19kxuo";
  const PAYSTACK_MONTHLY_NAIRA = "PLN_xxx9ou7b9aeycoi";
  // const PAYSTACK_ANNUAL_NAIRA = "PLN_58ihyifv6p0n6qq";
  const PAYSTACK_ANNUAL_NAIRA = "PLN_lxy1krxo3h3tkjv";
  // const PAYSTACK_ANNUAL_DOLLAR = "PLN_spp9fju14obvcxk";
  const PAYSTACK_ANNUAL_DOLLAR = "PLN_66rp1h0d2utmpzi";
  // const PAYSTACK_MONTHLY_DOLLAR = "PLN_cuedxqkgydib1a9";
  const PAYSTACK_MONTHLY_DOLLAR = "PLN_gaw516ah2tz4jyr";
  const [payType, setPayType] = useState("USD");
  const [planType, setPlanType] = useState("monthly");
  const [loading, setLoading] = useState(false);

  const setUpPayment = async () => {
    let planId;
    if (payType === "USD" && planType === "monthly") {
      planId = PAYSTACK_MONTHLY_DOLLAR;
    } else if (payType === "USD" && planType === "yearly") {
      planId = PAYSTACK_ANNUAL_DOLLAR;
    } else if (payType === "NGN" && planType === "monthly") {
      planId = PAYSTACK_MONTHLY_NAIRA;
    } else if (payType === "NGN" && planType === "yearly") {
      planId = PAYSTACK_ANNUAL_NAIRA;
    }
    setLoading(true);
    const data = await initializePayment({ plan: planId });
    if (data.authorization_url) {
      setLoading(false);
      window.location.href = data.authorization_url;
    }
    console.log(data);
    console.log(data.authorization_url);
  };

  return (
    <>
      <div
        className="w-full h-full fixed top-0 left-0 z-[1000] bg-black/60 flexmm font-inter"
        onClick={() => {
          setPro(false);
        }}
      >
        <div
          className="flex-shrink px-[22px] py-[18px] bg-white rounded-[12px] border-[1px] shadow-sm flexss gap-[14px]"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="cflexss gap-[24px] w-[304px] pr-[14px] border-r-[1px]">
            <div className="w-full cflexss gap-[12px] text-[12px]">
              <div className="w-full rounded-[6px] p-[13px] border-[#E6E6E6] flexbm border-[1px] bg-[#F5F5F5]">
                <div className="flexss gap-[8px] text-[13px] font-medium">
                  <img src="/barFlow.svg" alt="barFlow" />
                  <p>{publicInfo.domain}</p>
                </div>
                <p>1 member</p>
              </div>
              <p>
                All members of the {publicInfo.domain} workspace will be
                upgraded and gain access to more features.
              </p>
            </div>
            <p className="text-[20px] font-medium text-[#4D4E51]">
              Empower users to navigate your products
            </p>
            <div className="w-full cflexss gap-[17px] text-[16px]">
              <div className="flexsm gap-[7px] text-[#4D4E51]">
                <img
                  src="/publish.svg"
                  className="w-[20px] h-[20px]"
                  alt="publish"
                />
                <p>
                  Publish <u className="cursor-pointer">unlimited articles</u>
                </p>
              </div>
              <div className="flexsm gap-[7px] text-[#4D4E51]">
                <img
                  src="/linkVideo.svg"
                  className="w-[20px] h-[20px]"
                  alt="linkVideo"
                />
                <p>
                  Link <u className="cursor-pointer">unlimited videos</u> to
                  playlists
                </p>
              </div>
              <div className="flexsm gap-[7px] text-[#4D4E51]">
                <GlobeAltOutline size="20px" />
                <p>Add a custom domain</p>
              </div>
              <div className="flexsm gap-[7px] text-[#4D4E51]">
                <img
                  src="/clock.svg"
                  className="w-[20px] h-[20px]"
                  alt="clock"
                />
                <p>Access 1 year of revisions</p>
              </div>
              <div className="flexsm gap-[7px] text-[#4D4E51]">
                <img
                  src="chart.svg"
                  className="w-[20px] h-[20px]"
                  alt="chart"
                />
                <p>Track page performance metrics</p>
              </div>
            </div>
          </div>
          <div className="w-[304px] h-full rounded-[12px] p-[17px] bg-[#FCFCFC] border-[1px] cflexss gap-[16px]">
            <div className="w-full rounded-[10px] px-[4px] py-[3px] bg-[#E6E7E8] flexbm font-medium text-[14px] text-[#7C7D7E]">
              <div
                className={`rounded-[10px] w-1/2 px-[8px] py-[5px] flexmm gap-[4px] ${
                  payType === "USD" && "bg-white shadow-md"
                } cursor-pointer`}
                onClick={() => {
                  setPayType("USD");
                }}
              >
                <img
                  src="/usLogo.svg"
                  className="w-[20px] h-[20px]"
                  alt="pro"
                />
                <p>Pay in USD</p>
              </div>
              <div
                className={`rounded-[10px] w-1/2 px-[8px] py-[5px] flexmm gap-[4px] ${
                  payType === "NGN" && "bg-white shadow-md"
                } cursor-pointer`}
                onClick={() => {
                  setPayType("NGN");
                }}
              >
                <img
                  src="/nairaLogo.svg"
                  className="w-[20px] h-[20px]"
                  alt="pro"
                />
                <p>Pay in Naira</p>
              </div>
            </div>
            <div className="w-full cflexss gap-[8px] text-[12px] pb-[25.5px] border-b-[1px] font-normal">
              <div
                className={`w-full rounded-[8px] p-[11px] cflexss border ${
                  planType === "monthly" && "border-black border-[1px]"
                } cursor-pointer`}
                onClick={() => {
                  setPlanType("monthly");
                }}
              >
                <p>
                  <span className="font-medium text-[17px]">
                    {payType === "USD" ? "$7" : "N9,100"}
                  </span>{" "}
                  /mo
                </p>
                <p>Pay monthly</p>
              </div>
              <div
                className={`w-full rounded-[8px] p-[11px] cflexss border ${
                  planType === "yearly" && "border-black border-[1px]"
                } cursor-pointer`}
                onClick={() => {
                  setPlanType("yearly");
                }}
              >
                <p>
                  <span className="font-medium text-[17px]">
                    {payType === "USD" ? "$60" : "N78,000"}
                  </span>{" "}
                  /yr
                </p>
                <div className="w-full flexbm">
                  <p>{payType === "USD" ? "$5" : "N6,500"} /month</p>
                  <span className="text-[#34A853] font-semibold text-[13px]">
                    Save $24
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full cflexss gap-[36px] pt-[9.5px] font-medium text-[14px]">
              <div className="w-full flexbm text-[#4D4E51]">
                <p>Alps Pro (Annual)</p>
                <p>
                  {payType === "USD" && planType === "monthly" && "$7"}
                  {payType === "NGN" && planType === "monthly" && "N9,100"}
                  {payType === "USD" && planType === "yearly" && "$60"}
                  {payType === "NGN" && planType === "yearly" && "N78,000"}
                </p>
              </div>
              <button
                className="w-full bg-[#2C2B2B] px-[17px] flexmm py-[8px] font-semibold rounded-[10px] text-[16px] text-white shadow-md"
                onClick={setUpPayment}
              >
                {loading ? (
                  <div className="w-full flexmm gap-[10px]">
                    <p>Get Alps Pro</p> <Loader />
                  </div>
                ) : (
                  <p>Get Alps Pro</p>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlpsPro;
