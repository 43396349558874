import axios from "axios";
import { notify, notifyError } from "./toastify";
import ErrorPopUp from "../components/ErrorPopUp";
// import { access } from "fs";

// require('dotenv').config()
// const api = process.env.REACT_APP_API_URL.toString();
const api = "https://api.tryalps.com/api/v1";
// const api = "http://192.168.173.168:3030/api/v1";

// export function getCookie(cookieName: string) {
//   const name = cookieName + "=";
//   const decodedCookie = decodeURIComponent(document.cookie);
//   const cookieArray = decodedCookie.split(";");

//   for (let i = 0; i < cookieArray.length; i++) {
//     let cookie = cookieArray[i];
//     while (cookie.charAt(0) === " ") {
//       cookie = cookie.substring(1);
//     }
//     if (cookie.indexOf(name) === 0) {
//       return cookie.substring(name.length, cookie.length);
//     }
//   }
//   return null; // Return null if the cookie is not found
// }

export const setConfig = (accessToken) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    // withCredentials: true,
  };

  return config;
};

export const setImageConfig = (accessToken, type) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type":
        type === "themeType" ? "application/json" : "multipart/form-data",
    },
    withCredentials: true,
  };

  return config;
};

let count = 0;

export const userLogin = async (data, setPopUp, setModal) => {
  await axios
    .post(`${api}/auth/login`, data, {
      withCredentials: true,
    })
    .then((response) => {
      if (response.data.status === "success") {
        localStorage.setItem(
          "userData",
          JSON.stringify(response.data.data.data)
        );
        localStorage.setItem("alpsToken", response.data.token);
        localStorage.setItem(
          "alpsUser",
          JSON.stringify(response.data.data.data)
        );
        if (
          response.data.data.data.firstName &&
          response.data.data.data.lastName
        ) {
          window.location.pathname = "/collections";
        } else {
          window.location.pathname = "/info";
        }
      }
    })
    .catch((err) => {
      setPopUp(true);
      if (err.response.data.message) {
        setModal(
          <ErrorPopUp
            type="auth"
            head="Something's not right"
            text={err.response.data.message}
            setPopUp={setPopUp}
          />
        );
        // notifyError(err.response.data.message);
      } else {
        setModal(
          <ErrorPopUp
            type="error"
            head="Ooops!"
            text="Network Error"
            setPopUp={setPopUp}
          />
        );
        // notifyError("Network Error");
      }
    });
};

export const googleAuthentication = async (setPopUp, setModal) => {
  await axios
    .get(`${api}/google-auth`, {
      headers: {
        "Content-Type": "text/html",
      },
      withCredentials: false,
    })
    .then((response) => {
      console.log(response);
      // if (response.data.status === "success") {

      // }
    })
    .catch((err) => {
      console.log(err);
      // setPopUp(true);
      // if (err.response.data.message) {
      //   setModal(
      //     <ErrorPopUp
      //       type="auth"
      //       head="Something's not right"
      //       text={err.response.data.message}
      //       setPopUp={setPopUp}
      //     />
      //   );
      // } else {
      //   setModal(
      //     <ErrorPopUp
      //       type="error"
      //       head="Ooops!"
      //       text="Network Error"
      //       setPopUp={setPopUp}
      //     />
      //   );
      // }
    });
};

export const userRegistration = async (data, setPopUp, setModal) => {
  await axios
    .post(`${api}/auth/register`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    .then((response) => {
      if (response.data.status === "success") {
        localStorage.setItem(
          "userData",
          JSON.stringify(response.data.data.data)
        );
        localStorage.setItem("alpsToken", response.data.token);
        window.location.pathname = "/Info";
      }
    })
    .catch((err) => {
      setPopUp(true);
      if (err.response.data.message) {
        setModal(
          <ErrorPopUp
            type="auth"
            head="Something's not right"
            text={err.response.data.message}
            setPopUp={setPopUp}
          />
        );
      } else {
        setModal(
          <ErrorPopUp
            type="error"
            head="Ooops!"
            text="Network Error"
            setPopUp={setPopUp}
          />
        );
      }
    });
};

export const forgotPassword = async (data, setPopUp, setModal) => {
  await axios
    .post(`${api}/auth/forgot-password`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    .then((response) => {
      setPopUp(true);
      setModal(
        <ErrorPopUp
          type="success"
          head="Link sent!"
          text="Password reset link sent to your email"
          setPopUp={setPopUp}
        />
      );
      // notify("Password reset link sent to your email");
    })
    .catch((err) => {
      setPopUp(true);
      if (err.response.data.message) {
        setModal(
          <ErrorPopUp
            type="error"
            head="Ooops!"
            text={err.response.data.message}
            setPopUp={setPopUp}
          />
        );
      } else {
        setModal(
          <ErrorPopUp
            type="error"
            head="Ooops!"
            text="Network Error"
            setPopUp={setPopUp}
          />
        );
      }
    });
};

export const resetPassword = async (data, token) => {
  await axios
    .post(`${api}/auth/reset-password/${token}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    .then((response) => {
      window.location.pathname = "/login";
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
};

export const loginWithToken = async (data, token) => {
  await axios
    .post(`${api}/auth/login/reset-token/${token}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
    .then((response) => {
      localStorage.setItem("alpsToken", response.data.token);
      localStorage.setItem("alpsUser", JSON.stringify(response.data.data.data));
      window.location.pathname = "/collections";
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
};

export const getUserProfile = async (alpsToken, setModal, setPopUp) => {
  console.log("getting user profile");
  let result = [];
  await axios
    .get(`${api}/user/profile`, setConfig(alpsToken))
    .then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        console.log(response.data.data.data.profile);
        if (!response.data.data.data.profile && setPopUp && setModal) {
          setPopUp(true);
          setModal(
            <ErrorPopUp
              type="brandError"
              text={"Please update profile name before setting design"}
              buttonText="Add Brand Name"
              handleClick={() => {
                localStorage.setItem("addBrandRedirect", "true");
                window.location.pathname = "/add-brand";
              }}
              setPopUp={setPopUp}
            />
          );
        } else {
          result = response.data.data.data;
        }
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        console.log(err.response.data.message);
      } else {
        setModal(<ErrorPopUp type="error" text="Network Error" />);
      }
    });
  return result;
};

export const getUserData = async (alpsToken) => {
  let result = [];
  await axios
    .get(`${api}/user/data`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(result);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
        if (err.response.data.message === "jwt token has expired") {
          window.location.pathname = "/login";
        }
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const editUserProfile = async (token, body, setting = false) => {
  let result = [];
  await axios
    .patch(`${api}/user/profile`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
        if (!setting) {
          window.location.pathname = "/collections";
        }
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const updateCustomDomain = async (token, body) => {
  let result = [];
  await axios
    .patch(`${api}/user/domain`, body, setConfig(token))
    .then((response) => {
      console.log("customDomain", response);
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const setBrand = async (token, body, type) => {
  let result = [];
  await axios
    .post(`${api}/user/brand`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
        if (type === "completed") {
          window.location.pathname = "/collections";
        } else if (type === "addBrandRedirect") {
          localStorage.removeItem("addBrandRedirect");
          window.location.pathname = "/select-theme";
        }
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const updateGoogleTracker = async (token, body) => {
  let result = [];
  await axios
    .patch(`${api}/user/tracker`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const updatePassword = async (
  token,
  body,
  setPopUp,
  setModal,
  setUpdate
) => {
  let result = [];
  await axios
    .patch(`${api}/auth/update-password`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        setUpdate(false);
        setPopUp(true);
        setModal(
          <ErrorPopUp
            type="success"
            head="Password Updated!"
            text="Your password is updated successfully."
            setPopUp={setPopUp}
          />
        );
        result = response.data.data.data;
        localStorage.removeItem("alpsToken");
        localStorage.setItem("alpsToken", response.data.token);
        localStorage.setItem(
          "alpsUser",
          JSON.stringify(response.data.data.data)
        );
      }
    })
    .catch((err) => {
      setPopUp(true);
      if (err.response.data.message) {
        <ErrorPopUp
          type="auth"
          head="Ooops!"
          text={err.response.data.message}
          setPopUp={setPopUp}
        />;
      } else {
        <ErrorPopUp
          type="auth"
          head="Ooops!"
          text="Network Error"
          setPopUp={setPopUp}
        />;
      }
    });
  return result;
};

export const sendAccountVerification = async (
  token,
  body,
  setPopUp,
  setModal
) => {
  await axios
    .post(`${api}/user/send-verification-email`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        setPopUp(true);
        setModal(
          <ErrorPopUp
            type="success"
            head="Verification mail sent!"
            text="Verification link sent to your email"
            setPopUp={setPopUp}
          />
        );
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
};

export const verifyUserEmail = async (token, body) => {
  await axios
    .patch(`${api}/user/verify/${token}`, body)
    .then((response) => {
      console.log(token);
      if (response.data.status === "success") {
        notify(response.data.message);
        window.location.pathname = "/add-brand";
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
};

export const getUserPlaylist = async (alpsToken) => {
  let result = [];
  await axios
    .get(`${api}/playlist`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const searchPlaylist = async (alpsToken, searchQuery) => {
  let result = [];
  await axios
    .get(`${api}/playlist?name=${searchQuery}`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getPlaylistGuides = async (playlistId, alpsToken) => {
  let result = [];
  await axios
    .get(`${api}/guide/playlist/${playlistId}`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const createGuideVideo = async (token, body) => {
  let result = [];
  await axios
    .post(`${api}/guide`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const updateGuideVideo = async (token, body, guideId) => {
  let result = [];
  await axios
    .patch(`${api}/guide/${guideId}`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const publishGuide = async (token, playlistId, setPopUp, setModal) => {
  let result = [];
  await axios
    .patch(
      `${api}/playlist/publish/${playlistId}`,
      { status: "publish" },
      setConfig(token)
    )
    .then((response) => {
      if (response.data.status === "success") {
        setPopUp(true);
        result = response.data.data.data;
        setModal(
          <ErrorPopUp
            type="success"
            head="Playlist published!"
            text="We’ve saved your playlist"
            setPopUp={setPopUp}
          />
        );
      }
    })
    .catch((err) => {
      setPopUp(true);
      if (err.response.data.message) {
        setModal(
          <ErrorPopUp
            type="auth"
            head="Ooops!"
            text={err.response.data.message}
            setPopUp={setPopUp}
          />
        );
      } else {
        setModal(
          <ErrorPopUp
            type="auth"
            head="Ooops!"
            text="Network Error"
            setPopUp={setPopUp}
          />
        );
      }
    });
  return result;
};

export const archiveGuide = async (token, playlistId) => {
  let result = [];
  await axios
    .patch(
      `${api}/playlist/archive/${playlistId}`,
      { status: "archive" },
      setConfig(token)
    )
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const createPlaylist = async (token, body) => {
  let result = [];
  await axios
    .post(`${api}/playlist`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const updatePlaylist = async (token, body, playlistId) => {
  let result = [];
  await axios
    .patch(`${api}/playlist/${playlistId}`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        // notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const reorderPlaylist = async (token, body) => {
  let result;
  await axios
    .post(`${api}/playlist/reorder`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      }
    });
};

export const getUserCategories = async (alpsToken) => {
  let result = [];
  await axios
    .get(`${api}/category`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const searchCategories = async (alpsToken, searchQuery) => {
  let result = [];
  await axios
    .get(`${api}/category?name=${searchQuery}`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const createCategory = async (token, body) => {
  let result = [];
  await axios
    .post(
      `${api}/category`,
      body,
      body.file ? setImageConfig(token) : setConfig(token)
    )
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const updateCategory = async (token, body, categoryId) => {
  let result = [];
  await axios
    .patch(
      `${api}/category/${categoryId}`,
      body,
      body.file ? setImageConfig(token) : setConfig(token)
    )
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const publishCategory = async (
  token,
  body,
  categoryId,
  setPublished,
  setPopUp,
  setModal
) => {
  await axios
    .patch(`${api}/category/publish/${categoryId}`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        setPopUp(true);
        setModal(
          <ErrorPopUp
            type="success"
            head="Category published!"
            text="We’ve saved your category"
            setPopUp={setPopUp}
          />
        );
        // notify("Category Published");
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        setPopUp(true);
        setPublished(false);
        setModal(
          <ErrorPopUp
            type="link"
            head="Link article first."
            text="Not quite. You need to link this article to a category before you publish it."
            setPopUp={setPopUp}
          />
        );
      } else {
        notifyError("Network Error");
      }
    });
};

export const archiveCategory = async (
  token,
  body,
  categoryId,
  setPublished,
  setPopUp,
  setModal
) => {
  await axios
    .patch(`${api}/category/archive/${categoryId}`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        setPublished(false);
      }
    })
    .catch((err) => {
      setPopUp(true);
      if (err.response.data.message) {
        setPublished(true);
      } else {
        setModal(
          <ErrorPopUp
            type="auth"
            head="Ooops"
            text="Network Error"
            setPopUp={setPopUp}
          />
        );
      }
    });
};

export const reorderCategory = async (token, body) => {
  await axios
    .post(`${api}/category/reorder`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
      } else {
      }
    });
};

export const linkArticle = async (body, articleId, token) => {
  let result = [];
  await axios
    .patch(`${api}/article/${articleId}/link`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const linkMultipleArticles = async (body, catId, token) => {
  let result = [];
  await axios
    .patch(`${api}/category/link/${catId}`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getAllArticles = async (alpsToken) => {
  let result = [];
  console.log("getting all articles");
  await axios
    .get(`${api}/article/user`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
        console.log(response);
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
      console.log(err);
    });
  return result;
};

export const searchArticles = async (alpsToken, searchQuery) => {
  let result = [];
  await axios
    .get(`${api}/article/user?name=${searchQuery}`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getUnlinkedArticles = async (alpsToken) => {
  let result = [];
  await axios
    .get(`${api}/article/unlinked`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getStatusArticles = async (status, alpsToken) => {
  let result = [];
  await axios
    .get(`${api}/article/user?status=${status}`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getCategoryArticles = async (categoryId, alpsToken) => {
  let result = [];
  await axios
    .get(`${api}/article/category/${categoryId}`, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const getReportData = async (alpsToken) => {
  let result = [];
  await axios
    .get(`${api}/report`, setConfig(alpsToken))
    .then((response) => {
      result = response.data;
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
      console.log(err)
    });
  return result;
};

export const createArticle = async (token, body) => {
  let result = [];
  await axios
    .post(
      `${api}/article`,
      body,
      body.file ? setImageConfig(token) : setConfig(token)
    )
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const updateArticle = async (token, body, articleId) => {
  let result = [];
  await axios
    .patch(
      `${api}/article/${articleId}`,
      body,
      body.file ? setImageConfig(token) : setConfig(token)
    )
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const publishArticle = async (
  token,
  body,
  articleId,
  setContent,
  id,
  setPLoading,
  setPopUp,
  setModal
) => {
  await axios
    .patch(`${api}/article/${articleId}/publish`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
        setPopUp(true);
        // notify("Article Published");
        setModal(
          <ErrorPopUp
            type="success"
            head="Article published!"
            text="We’ve published your article"
            setPopUp={setPopUp}
          />
        );
        if (id) {
          window.location.pathname = "/articles";
        } else {
          setContent("articles");
        }
      }
    })
    .catch((err) => {
      setPopUp(true);
      if (err.response.data.message) {
        setModal(
          <ErrorPopUp
            type="link"
            head="Connect category first!"
            text="Not quite. You need to connect a category to this article before you publish it."
            setPopUp={setPopUp}
          />
        );
        setPLoading(false);
      } else {
        setModal(
          <ErrorPopUp
            type="auth"
            head="Ooops!"
            text="Network Error"
            setPopUp={setPopUp}
          />
        );
      }
    });
};

export const reorderArticle = async (token, body) => {
  await axios
    .post(`${api}/article/reorder`, body, setConfig(token))
    .then((response) => {
      if (response.data.status === "success") {
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
      } else {
      }
    });
};

export const uploadImage = async (body) => {
  let result = [];
  let alpsToken = localStorage.getItem("alpsToken");
  await axios
    .post(`${api}/article/upload`, body, setImageConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = {
          success: 1,
          file: {
            url: response.data.data.data.url,
            borderRadius: "30px",
            width: "auto",
            height: "auto",
          },
          caption: "Roadster // tesla.com",
          withBorder: false,
          withBackground: false,
          stretched: true,
        };
      }
    })
    .catch((err) => {
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const updateUserDesign = async (body, type, setModal, setPopUp) => {
  let result = [];
  let alpsToken = localStorage.getItem("alpsToken");
  await axios
    .patch(`${api}/user/design`, body, setImageConfig(alpsToken, type))
    .then((response) => {
      if (response.data.status === "success") {
        setPopUp(true);
        result = response.data.data.data;
        setModal(
          <ErrorPopUp
            type="success"
            head="Design Updated!"
            text={response.data.message}
            setPopUp={setPopUp}
          />
        );
      }
      console.log(response);
    })
    .catch((err) => {
      console.log(err);
      setPopUp(true);
      if (
        err.response.data.message ===
        "Please update profile name before setting design"
      ) {
        setModal(
          <ErrorPopUp
            type="brandError"
            head="Add brand name"
            text={err.response.data.message}
            buttonText="Add brand name"
            handleClick={() => {
              localStorage.setItem("addBrandRedirect", "true");
              window.location.pathname = "/add-brand";
            }}
            setPopUp={setPopUp}
          />
        );
      } else {
        setModal(
          <ErrorPopUp
            type="auth"
            head="Ooops!"
            text="Something Broke! Network Error!"
            setPopUp={setPopUp}
          />
        );
      }
    });
  return result;
};

export const updateSocialLinks = async (body) => {
  let result = [];
  let alpsToken = localStorage.getItem("alpsToken");
  await axios
    .patch(`${api}/user/update-socials`, body, setConfig(alpsToken))
    .then((response) => {
      console.log(response)
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};

export const initializePayment = async (body) => {
  let result = [];
  let alpsToken = localStorage.getItem("alpsToken");
  await axios
    .post(`${api}/payment`, body, setConfig(alpsToken))
    .then((response) => {
      if (response.data.status === "success") {
        result = response.data.data.data;
      }
    })
    .catch((err) => {
      console.log(err);
      if (err.response.data.message) {
        notifyError(err.response.data.message);
      } else {
        notifyError("Network Error");
      }
    });
  return result;
};
