import React, { useState } from "react";
import { ChevronRight, ChevronDown, Link } from "heroicons-react";
import ManageSocialLinks from "../../components/ManageSocialLinks";

const Footer = ({
  view,
  setView,
  designInfo,
  setDesignInfo,
  userProfile,
  setPopUp,
  setModal,
}) => {
  const [viewFooter, setViewFooter] = useState(false);
  return (
    <>
      {viewFooter ? (
        <>
          <div className="w-[85%] flex-shrink bg-[#F5F5F5] border-[2px] rounded-[8px] cflexss">
            <div
              className={`w-full flexbm px-[14px] py-[12px] rounded-t-[8px] cursor-pointer`}
              onClick={() => {
                setViewFooter(false);
              }}
            >
              <p>Footer</p>
              <ChevronDown size="24px" />
            </div>
            <div className="w-full bg-white cflexss rounded-b-[8px] text-[#656667] pb-[20px] font-normal text-[14px]">
              <div className="w-full flexbm p-[16px] pt-[20px] gap-[16px]">
                <div className="cflexss gap-[4px] w-[70%]">
                  <p className="font-semibold text-[#36383A]">
                    Contact us card
                  </p>
                  <p>Add contact us section to footer</p>
                </div>
                <div
                  className={`w-[40px] h-[25px] rounded-full ${
                    designInfo.footerContactUsCard === "true"
                      ? "bg-[#1F2023] flexem"
                      : "bg-[#E6E6E6] flexsm"
                  } p-[2px] cursor-pointer`}
                  onClick={() => {
                    if (designInfo.footerContactUsCard === "false") {
                      setDesignInfo({
                        ...designInfo,
                        footerContactUsCard: "true",
                      });
                    }
                    if (
                      designInfo.footerAlpsLink === "true" &&
                      designInfo.footerContactUsCard === "true"
                    ) {
                      setDesignInfo({
                        ...designInfo,
                        footerContactUsCard: "false",
                      });
                    }
                  }}
                >
                  <div className="w-[20px] h-[20px] rounded-full bg-white" />
                </div>
              </div>

              <div className="w-full cflexss p-[16px] pb-[40px] gap-[16px]">
                <div className="cflexss gap-[6px]">
                  <p className="font-semibold text-[#36383A]">Social links</p>
                  <p>Add and manage links to your social account</p>
                </div>
                <div
                  className="rounded-[8px] px-[16px] py-[8px] gap-[8px] flexmm text-[#1F2023] bg-[#E6E6E6] font-medium cursor-pointer"
                  onClick={() => {
                    setModal(
                      <ManageSocialLinks
                        setPopUp={setPopUp}
                        userProfile={userProfile}                        
                      />
                    );
                    setPopUp(true);
                  }}
                >
                  <Link size="20px" />
                  <p>Manage links</p>
                </div>
              </div>

              <div className="w-full flexbm p-[16px] pt-[20px] gap-[16px]">
                <div className="cflexss gap-[4px] w-[70%]">
                  <p className="font-semibold text-[#36383A]">Alps link</p>
                  <p>Display a subtle link to Alps website on footer</p>
                </div>
                <div
                  className={`w-[40px] h-[25px] rounded-full ${
                    designInfo.footerAlpsLink === "true"
                      ? "bg-[#1F2023] flexem"
                      : "bg-[#E6E6E6] flexsm"
                  } p-[2px] cursor-pointer`}
                  onClick={() => {
                    if (designInfo.footerAlpsLink === "false") {
                      setDesignInfo({
                        ...designInfo,
                        footerAlpsLink: "true",
                      });
                    }
                    if (
                      designInfo.footerAlpsLink === "true" &&
                      designInfo.footerContactUsCard === "true"
                    ) {
                      setDesignInfo({
                        ...designInfo,
                        footerAlpsLink: "false",
                      });
                    }
                  }}
                >
                  <div className="w-[20px] h-[20px] rounded-full bg-white" />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`w-[85%] flexbm flex-shrink ${
              view === "Footer" && "bg-[#F5F5F5]"
            } px-[14px] py-[12px] rounded-[8px] cursor-pointer border-[2px]`}
            onClick={() => {
              setViewFooter(true);
              setView("Footer");
            }}
          >
            <p>Footer</p>
            <ChevronRight size="24px" />
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
