import React, { useState, useEffect } from "react";
import { Plus, X, TrashOutline } from "heroicons-react";
import Loader from "./Loader/Loader";
import { updateSocialLinks } from "../services/request";
import Button from "./Button";

const ManageSocialLinks = ({ setPopUp, userProfile }) => {
  const linkRegex =
    /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;
  const [socialLinks, setSocialLinks] = useState({
    x: userProfile?.profile?.x,
    facebook: userProfile?.profile?.facebook,
    instagram: userProfile?.profile?.instagram,
    youtube: userProfile?.profile?.youtube,
    linkedIn: userProfile?.profile?.linkedIn,
  });
  const [loading, setLoading] = useState(false);
  const [changing, setChanging] = useState(false);
  const [xError, setXError] = useState(false);
  const [facebookError, setFacebookError] = useState(false);
  const [instagramError, setInstagramError] = useState(false);
  const [youtubeError, setYoutubeError] = useState(false);
  const [linkedInError, setLinkedInError] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setSocialLinks({ ...socialLinks, [name]: value });
    setChanging(!changing);
  };

  const validateSocialLinks = (links) => {
    const xRegex = /^https?:\/\/(www\.)?x\.com\/[a-zA-Z0-9_]{1,15}$/;
    const facebookRegex =
      /^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9(\.\?)?]/;
    const instagramRegex =
      /^https?:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9._-]+\/?$/;
    const youtubeRegex =
      /^https?:\/\/(www\.)?(youtube\.com\/(channel\/|c\/|user\/|watch\?v=|@)|youtu\.be\/)[a-zA-Z0-9_-]+$/;
    const linkedInRegex =
      /^https?:\/\/(www\.)?linkedin\.com\/(in|company)\/[a-zA-Z0-9_-]+\/?$/;

    if ((links.x && xRegex.test(links.x)) || !links.x) {
      setXError(false);
    } else if (links.x && !xRegex.test(links.x)) {
      setXError(true);
    }

    if (
      (links.facebook && facebookRegex.test(links.facebook)) ||
      !links.facebook
    ) {
      setFacebookError(false);
    } else if (links.facebook && !facebookRegex.test(links.facebook)) {
      setFacebookError(true);
    }

    if (
      (links.instagram && instagramRegex.test(links.instagram)) ||
      !links.instagram
    ) {
      setInstagramError(false);
    } else if (links.instagram && !instagramRegex.test(links.instagram)) {
      setInstagramError(true);
    }

    if ((links.youtube && youtubeRegex.test(links.youtube)) || !links.youtube) {
      setYoutubeError(false);
    } else if (links.youtube && !youtubeRegex.test(links.youtube)) {
      setYoutubeError(true);
    }

    if (
      (links.linkedIn && linkedInRegex.test(links.linkedIn)) ||
      !links.linkedIn
    ) {
      setLinkedInError(false);
    } else if (links.linkedIn && !linkedInRegex.test(links.linkedIn)) {
      setLinkedInError(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !xError &&
      !facebookError &&
      !youtubeError &&
      !instagramError &&
      !linkedInError
    ) {
      setLoading(true);
      await updateSocialLinks(socialLinks);
      setLoading(false);
      setPopUp(false);
    }
  };

  useEffect(() => {
    console.log(userProfile);
    validateSocialLinks(socialLinks);
  }, [changing]);
  return (
    <>
      <div
        className="fixed top-0 left-0 z-[999] w-full h-full bg-[#000000]/20 flexms pt-[100px]"
        onClick={() => {
          setPopUp(false);
        }}
      >
        <div
          className="bg-white w-[500px] rounded-[10px] text-[#656667] text-[14px] p-[24px] shadow-sm flex-shrink"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <form className="w-full cflexss gap-[20px]" onSubmit={handleSubmit}>
            <div className="flexbm w-full">
              <div className="cflexss gap-[6px]">
                <p className="font-semibold text-[18px] text-[#36383A]">
                  Social links
                </p>
                <p>Add and manage your social links in the footer</p>
              </div>
              <X
                size="20px"
                className="cursor-pointer"
                onClick={() => {
                  setPopUp(false);
                }}
              />
            </div>
            <div className="w-full cflexss gap-[8px] overflow-y-auto">
              <div className="w-full cflexsm gap-[16px] bg-white">
                <div className="w-full flexbm text-[#1F2023] font-medium gap-[8px] flex-wrap">
                  <div className="flexsm flex-shrink">
                    <p>X (Official twitter)</p>
                  </div>
                  <div className="cflexms w-[250px] gap-[8px] flex-shrink">
                    <input
                      type="link"
                      name="x"
                      value={socialLinks.x}
                      onChange={handleChange}
                      className="w-full px-[12px] py-[8px] outline-none border-[2px] rounded-[6px]"
                    />
                    {xError && (
                      <p className="text-[12px] text-red-600">
                        Please provide a valid X url!
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-full flexbm text-[#1F2023] font-medium gap-[8px] flex-wrap">
                  <div className="flexsm flex-shrink">
                    <p>Facebook</p>
                  </div>
                  <div className="cflexms w-[250px] gap-[8px] flex-shrink">
                    <input
                      type="link"
                      name="facebook"
                      value={socialLinks.facebook}
                      onChange={handleChange}
                      className="w-full px-[12px] py-[8px] outline-none border-[2px] rounded-[6px]"
                    />
                    {facebookError && (
                      <p className="text-[12px] text-red-600">
                        Please provide a valid facebook url!
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-full flexbm text-[#1F2023] font-medium gap-[8px] flex-wrap">
                  <div className="flexsm w-[100px] flex-shrink">
                    <p>Instagram</p>
                  </div>
                  <div className="cflexms w-[250px] gap-[8px] flex-shrink">
                    <input
                      type="link"
                      name="instagram"
                      value={socialLinks.instagram}
                      onChange={handleChange}
                      className="w-full px-[12px] py-[8px] outline-none border-[2px] rounded-[6px]"
                    />
                    {instagramError && (
                      <p className="text-[12px] text-red-600">
                        Please provide a valid instagram url!
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-full flexbm text-[#1F2023] font-medium gap-[8px] flex-wrap">
                  <div className="flexsm w-[100px] flex-shrink">
                    <p>YouTube</p>
                  </div>
                  <div className="cflexms w-[250px] gap-[8px] flex-shrink">
                    <input
                      type="link"
                      name="youtube"
                      value={socialLinks.youtube}
                      onChange={handleChange}
                      className="w-full px-[12px] py-[8px] outline-none border-[2px] rounded-[6px]"
                    />
                    {youtubeError && (
                      <p className="text-[12px] text-red-600">
                        Please provide a valid youtube url!
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-full flexbm text-[#1F2023] font-medium gap-[8px] flex-wrap">
                  <div className="flexsm w-[100px] flex-shrink">
                    <p>LinkedIn</p>
                  </div>
                  <div className="cflexms w-[250px] gap-[8px] flex-shrink">
                    <input
                      type="link"
                      name="linkedIn"
                      value={socialLinks.linkedIn}
                      onChange={handleChange}
                      className="w-full px-[12px] py-[8px] outline-none border-[2px] rounded-[6px]"
                    />
                    {linkedInError && (
                      <p className="text-[12px] text-red-600">
                        Please provide a valid linkedIn url!
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flexem gap-[12px]">
              <Button gap="8px" type="submit" handleClick={() => {}}>
                {loading ? (
                  <div className="flexmm gap-[10px]">
                    <Loader />
                    <p>Save</p>
                  </div>
                ) : (
                  <p>Save</p>
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ManageSocialLinks;
