import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import {
  ArrowLeft,
  ArrowRight,
  InformationCircleOutline,
} from "heroicons-react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Paginated, GetPaginatedData } from "../../atomicComponents/Pagination";

const SearchTable = ({ emptySearches, loading }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const PAGINATION = 5;
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
    // Fetch and display data for the selected page
  };

  useEffect(() => {
    setPageCount(Paginated(emptySearches, PAGINATION));
  }, []);

  return (
    <>
      <div className="w-full cflexss gap-[20px] mt-[20px]">
        <div className="flexsm gap-[10px]">
          <p className="font-medium text-[18px]">Empty searches</p>
          <InformationCircleOutline size="20px" />
        </div>
        <table className="w-full p-0 m-0">
          {/* <thead scope="column" className="text-[16px] text-[#4D4E51]"> */}
          <thead className="text-[16px] text-[#4D4E51]">
            <tr className="w-full flexbm bg-[#F5F5F5]">
              <td className="px-[24px] py-[16px] font-[600]">
                <p>Query</p>
              </td>
              <td className="px-[24px] py-[16px] font-[600]">
                <p>Count</p>
              </td>
            </tr>
          </thead>
          <tbody className="w-full">
            {emptySearches?.length > 0 &&
              GetPaginatedData(currentPage, PAGINATION, emptySearches)?.map(
                (search, index) => {
                  return (
                    <>
                      <tr
                        key={index}
                        className="cursor-pointer flexbm border-b-[1px] bg-white hover:bg-[#f5f5f5]"
                      >
                        <td className="px-[24px] text-[#1072E5] font-normal py-[16px]">
                          <p>{search.title}</p>
                        </td>

                        <td className="px-[24px] font-[700] py-[16px] flexbm gap-[16px]">
                          <p>{search.count}</p>
                        </td>
                      </tr>
                    </>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
      {loading && (
        <div className="w-full">
          <SkeletonTheme baseColor="#f5f5f5" highlightColor="#cacecf">
            {new Array(6).fill().map((x, index) => (
              <Skeleton
                key={index}
                containerClassName="w-full h-[80px] rounded-[10px]"
                className="w-full h-[100vh]"
                width={"100%"}
                height={"45px"}
                borderRadius={"5px"}
              />
            ))}
          </SkeletonTheme>
        </div>
      )}
      <div className="w-full flexem mt-[10px]">
        <ReactPaginate
          previousLabel={<ArrowLeft size="20px" />}
          previousClassName={"p-[10px] border-[2px] rounded-l-[10px]"}
          nextClassName={"p-[10px] border-[2px] rounded-r-[10px]"}
          nextLabel={<ArrowRight size="20px" />}
          breakLabel={"..."}
          breakClassName={"w-full p-[10px]"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          pageClassName={"px-[20px] py-[10px] border-[2px]"}
          onPageChange={handlePageClick}
          containerClassName={"text-[14px] lg:text-[12px] flexem"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};

export default SearchTable;
