import React, { useState, useEffect } from "react";
import { Plus } from "heroicons-react";
import TableOfArticle from "./TableOfArticles";
import ChangeCover from "../../components/ChangeCover";
import ConnectArticle from "../../components/ConnectArticle";
import Button from "../../components/Button";
import Loader from "../../components/Loader/Loader";
import Loader2 from "../../components/Loader/Loader2";
import ErrorPopUp from "../../components/ErrorPopUp";
import {
  createCategory,
  updateCategory,
  publishCategory,
  archiveCategory,
  getUserProfile,
} from "../../services/request";

const CategoryContent = ({ category, setRefresh, refresh }) => {
  const [categoryDetails, setCategoryDetails] = useState({
    name: `${category ? category.name : ""}`,
    file: `${category ? category.coverURL : ""}`,
    description: `${category ? category.description : ""}`,
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modal, setModal] = useState(null);
  const [popUp, setPopUp] = useState(false);
  const [published, setPublished] = useState(false);
  const [pLoading, setPLoading] = useState(false);
  const [aLoading, setALoading] = useState(false);
  const [articles, setArticles] = useState([]);
  const [lRefresh, setLRefresh] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [userProfile, setUserProfile] = useState("");
  const [categoryId, setCategoryId] = useState(
    `${category ? category.id : ""}`
  );
  const [changing, setChanging] = useState(false);
  const [coverUpdated, setCoverUpdated] = useState(false)
  const [type, setType] = useState(`${category ? "update" : "create"}`);
  const [loading, setLoading] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(
    `${category ? category.updatedAt : ""}`
  );
  // const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setCategoryDetails({ ...categoryDetails, [name]: value });
    setChanging(!changing);
  };

  function getTimeDifference(timestamp) {
    const currentTime = new Date();
    const difference = currentTime.getTime() - timestamp.getTime();

    // Convert milliseconds to seconds
    const seconds = Math.floor(difference / 1000);

    if (seconds < 60) {
      if (seconds > 0 && seconds < 60) {
        return seconds + " seconds ago";
      } else if (seconds === 0) {
        return " just now";
      }
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return minutes + ` ${minutes > 1 ? "minutes ago" : "minute ago"}`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return hours + ` ${hours > 1 ? "hours ago" : "hour ago"}`;
    }

    const days = Math.floor(hours / 24);
    if (days < 30) {
      return days + ` ${days > 1 ? "days ago" : "day ago"}`;
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
      return months + ` ${months > 1 ? "months ago" : "month ago"}`;
    }

    const years = Math.floor(months / 12);
    return years + ` ${years > 1 ? "years ago" : "year ago"}`;
  }

  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeAgo = getTimeDifference(new Date(updatedAt));
      setTimeAgo(newTimeAgo);
    }, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [updatedAt]);

  function generateRandomCategoryName() {
    // List of adjectives and nouns to form the category name
    var adjectives = [
      "Creative",
      "Innovative",
      "Unique",
      "Dynamic",
      "Vibrant",
      "Inspiring",
      "Fresh",
    ];
    var nouns = [
      "Ideas",
      "Creations",
      "Projects",
      "Ventures",
      "Endeavors",
      "Enterprises",
    ];

    // Select a random adjective and noun
    var randomAdjective =
      adjectives[Math.floor(Math.random() * adjectives.length)];
    var randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

    // Combine them to form the category name
    var categoryName = randomAdjective + " " + randomNoun;

    return categoryName;
  }

  const publishCategoryRequest = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (categoryId && category?.status !== "published" && articles.length > 0) {
      setPLoading(true);
      await publishCategory(
        alpsToken,
        {
          status: "published",
        },
        categoryId,
        setPublished,
        setPopUp,
        setModal
      );
      setPLoading(false);
      setRefresh(!refresh);
    }
    if (!categoryId) {
      setPopUp(true);
      setModal(
        <ErrorPopUp
          type="link"
          head="Kindly Update Your Category"
          text="Not quite. You need to create your category before you can publish."
          setPopUp={setPopUp}
        />
      );
    }
    if (articles.length === 0) {
      setPopUp(true);
      setModal(
        <ErrorPopUp
          type="link"
          head="Connect article first!"
          text="Not quite. You need to connect an article to this category before you publish it."
          setPopUp={setPopUp}
        />
      );
    }
  };

  const archiveCategoryRequest = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (categoryId && category?.status === "published") {
      setALoading(true);
      await archiveCategory(
        alpsToken,
        {
          status: "archived",
        },
        categoryId,
        setPublished,
        setPopUp,
        setModal
      );
      setALoading(false);
      setRefresh(!refresh);
    }
  };

  const createCategoryRequest = async () => {
    let data;
    let alpsToken = localStorage.getItem("alpsToken");
    if (alpsToken && type === "create" && !categoryId) {
      if (categoryDetails["file"].file) {
        setLoading(true);
        data = await createCategory(alpsToken, {
          name: categoryDetails["name"]
            ? categoryDetails["name"]
            : "Untitled Category",
          file: categoryDetails["file"].file,
          description: categoryDetails["description"],
        });
        setLoading(false);
        if (data) {
          setCategoryId(data?.id);
          setUpdatedAt(data?.updatedAt);
          localStorage.setItem("alpsCategoryId", data?.id);
        }
        setTimeAgo("Just now");
        setType("update");
      } else {
        setLoading(true);
        data = await createCategory(alpsToken, {
          name: categoryDetails["name"]
            ? categoryDetails["name"]
            : "Untitled Category",
          coverURL: categoryDetails["file"]
            ? categoryDetails["file"]
            : "https://www.betterinvesting.org/getmedia/0e845518-2f15-48fb-a01d-8a192aafd39d/help-center-home.png?width=800&height=452&ext=.png",
          description: categoryDetails["description"],
        });
        setLoading(false);
        if (data) {
          setCategoryId(data?.id);
          setUpdatedAt(data?.updatedAt);
          localStorage.setItem("alpsCategoryId", data?.id);
        }
        setTimeAgo("Just now");
        setType("update");
      }
    } else if ((alpsToken && type === "update") || categoryId) {
      let data;
      if (categoryDetails["file"].file) {
        setLoading(true);
        data = await updateCategory(
          alpsToken,
          {
            name: categoryDetails["name"]
              ? categoryDetails["name"]
              : "Untitled Category",
            file: categoryDetails["file"].file,
            description: categoryDetails["description"],
            articleBody: categoryDetails["articleBody"],
          },
          categoryId
        );
        setTimeAgo("Just now");
        setUpdatedAt(data?.updatedAt);
        setLoading(false);
      } else {
        setLoading(true);
        data = await updateCategory(
          alpsToken,
          {
            name: categoryDetails["name"]
              ? categoryDetails["name"]
              : "Untitled Category",
            coverURL: categoryDetails["file"]
              ? categoryDetails["file"]
              : "https://www.betterinvesting.org/getmedia/0e845518-2f15-48fb-a01d-8a192aafd39d/help-center-home.png?width=800&height=452&ext=.png",
            description: categoryDetails["description"],
            articleBody: categoryDetails["articleBody"],
          },
          categoryId
        );
        setTimeAgo("Just now");
        setUpdatedAt(data?.updatedAt);
        setLoading(false);
      }
    }
  };

  const getProfile = async () => {
    const alpsToken = localStorage.getItem("alpsToken");
    let userProfile = await getUserProfile(alpsToken);
    if (userProfile) {
      setUserProfile(userProfile);
    }
  };

  const updateCover = async () => {
    if (categoryId) {
      setType("update");
    }
    await createCategoryRequest();
    setCoverUpdated(false)
    setRefresh(!refresh);
  };

  useEffect(() => {
    if (categoryDetails["file"] && coverUpdated) {
      updateCover();
    }
  }, [categoryDetails["file"]]);

  useEffect(() => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (!alpsToken) {
      window.location.pathname = "/login";
    } else {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {popUp && modal}
      <div
        className="w-full cflexss h-full overflow-y-auto"
        onClick={() => {
          setIsOpenModal(false);
        }}
      >
        <div className="w-full flexbm text-[14px] py-[16px] px-[30px] border-b-[2px]">
          <div className="flexsm gap-[15px]">
            {category ? (
              <>
                <p className="capitalize">{category.status.toLowerCase()}</p>
                <div
                  className={`w-[8px] h-[8px] rounded-full ${
                    category.status === "published" && "bg-[#22C55E]"
                  } ${category.status === "draft" && "bg-yellow-400"} ${
                    category.status === "archived" && "bg-red-600"
                  }`}
                />
                <p className="text-[#7D7D7D]">
                  {updatedAt && `Last Saved ${timeAgo}`}
                </p>
              </>
            ) : (
              <>
                {published ? <p>Published</p> : <p>Archived</p>}
                <div
                  className={`w-[8px] h-[8px] rounded-full ${
                    published ? "bg-[#22C55E]" : "bg-red-600"
                  } `}
                />
                <p className="text-[#7D7D7D]">
                  {updatedAt && `Last Saved ${timeAgo}`}
                </p>
              </>
            )}
          </div>
          <div className="flexmm gap-[14px] font-[500]">
            {category ? (
              <>
                {category?.status.toLowerCase() !== "archived" &&
                  (aLoading ? (
                    <>
                      <div className="flexmm gap-[10px] cursor-pointer">
                        <Loader2 />
                        <p>Archive</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="flexmm gap-[10px] cursor-pointer"
                        onClick={() => {
                          archiveCategoryRequest();
                        }}
                      >
                        <p>Archive</p>
                      </div>
                    </>
                  ))}
              </>
            ) : (
              <>
                {published &&
                  (aLoading ? (
                    <>
                      <div className="flexmm gap-[10px] cursor-pointer">
                        <Loader2 />
                        <p>Archive</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="flexmm gap-[10px] cursor-pointer"
                        onClick={() => {
                          archiveCategoryRequest();
                        }}
                      >
                        <p>Archive</p>
                      </div>
                    </>
                  ))}
              </>
            )}
            <div
              className="flexmm px-[12px] py-[6px] gap-[8px] cursor-pointer rounded-[10px] bg-[#F5F5F5]"
              onClick={() => {
                if (
                  category?.status.toLowerCase() === "published" &&
                  userProfile
                ) {
                  window.open(
                    `https://${
                      userProfile?.profile?.name
                    }.tryalps.app/category/${categoryDetails["name"].replace(
                      / /g,
                      "-"
                    )}`,
                    "_blank"
                  );
                }
              }}
            >
              <img
                src="/globe.svg"
                className="w-[14px] h-[14px]"
                alt="globe-icon"
              />
              <p>Preview</p>
            </div>
            {category ? (
              <>
                {category?.status.toLowerCase() !== "published" && (
                  <div className="rounded-[10px] shadow-md">
                    <Button
                      paddingX="17px"
                      paddingY="8px"
                      handleClick={() => {
                        publishCategoryRequest();
                      }}
                    >
                      {pLoading ? (
                        <div className="flexmm gap-[10px]">
                          <Loader />
                          <p>Publish</p>
                        </div>
                      ) : (
                        <p>Publish</p>
                      )}
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                {!published && (
                  <div className="rounded-[10px] shadow-md">
                    <Button
                      paddingX="17px"
                      paddingY="8px"
                      handleClick={() => {
                        publishCategoryRequest();
                      }}
                    >
                      {pLoading ? (
                        <div className="flexmm gap-[10px]">
                          <Loader />
                          <p>Publish</p>
                        </div>
                      ) : (
                        <p>Publish</p>
                      )}
                    </Button>
                  </div>
                )}
              </>
            )}
            <Button
              paddingX="17px"
              paddingY="8px"
              handleClick={async () => {
                if (categoryId) {
                  setType("update");
                }
                await createCategoryRequest();
                setRefresh(!refresh);
              }}
            >
              {loading ? (
                <div className="flexmm gap-[10px]">
                  <Loader />
                  <p>Update</p>
                </div>
              ) : (
                <p>Update</p>
              )}
            </Button>
          </div>
        </div>
        <div className="px-[10%] py-[40px] h-full w-full cflexss gap-[80px]">
          <div className="cflexss w-full gap-[20px]">
            <input
              value={categoryDetails["name"]}
              name="name"
              className="w-full text-[32px] outline-none border-none"
              type="text"
              placeholder="Category Title"
              onChange={handleChange}
            />
            <div
              className={`relative z-0 w-full bg-[#D9D9D9] rounded-[10px] ${
                categoryDetails["file"] ? "h-[160px]" : "h-[64px]"
              }`}
            >
              {categoryDetails["file"] && (
                <>
                  {categoryDetails["file"].base64 ? (
                    <img
                      src={categoryDetails["file"].base64}
                      alt="cover"
                      className="w-full h-full rounded-[10px] object-cover"
                    />
                  ) : (
                    <img
                      src={categoryDetails["file"]}
                      alt="cover"
                      className="w-full h-full rounded-[10px] object-cover"
                    />
                  )}
                </>
              )}
              <div
                className="absolute top-[25%] z-0 right-[22px] cursor-pointer rounded-[10px] px-[12px] py-[6px] bg-white text-[14px] font-[500]"
                onClick={(e) => {
                  setModalContent(
                    <ChangeCover
                      setIsOpenModal={setIsOpenModal}
                      setCategoryDetails={setCategoryDetails}
                      categoryDetails={categoryDetails}
                      setChanging={setChanging}
                      changing={changing}                            
                      setCoverUpdated={setCoverUpdated}             
                    />
                  );
                  setIsOpenModal(!isOpenModal);
                  e.stopPropagation();
                }}
              >
                Change cover
                <div className="relative">{isOpenModal && modalContent}</div>
              </div>
            </div>
            <div className="w-full cflexss gap-[11px]">
              <p className="text-[12px] text-[#989898] font-[500]">
                DESCRIPTION (Optional)
              </p>
              <textarea
                value={categoryDetails["description"]}
                name="description"
                className="w-full text-[16px] outline-none border-none resize-none"
                type="text"
                placeholder="The description of the category"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full cflexss gap-[20px]">
            <div className="w-full flexbm">
              <div className="rounded-[6px] px-[12px] py-[9px] w-[308px] flexsm gap-[10px] border-[1px]">
                <img
                  src="/search-icon.svg"
                  alt="search-icon"
                  className="w-[20px] h-[20px] cursor-pointer"
                />
                <input
                  type="search"
                  placeholder="Search articles"
                  className="outline-none w-full"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="flexmm gap-[8px]">
                <div
                  className="bg-[#F5F5F5] rounded-[10px] px-[12px] py-[6px] gap-[8px] flexmm font-[500] text-[14px] cursor-pointer"
                  onClick={(e) => {
                    setModalContent(
                      <ConnectArticle
                        setIsOpenModal={setIsOpenModal}
                        categoryDetails={categoryDetails}
                        categoryId={categoryId}
                        setType={setType}
                        createCategoryRequest={createCategoryRequest}
                        setRefresh={setLRefresh}
                        refresh={lRefresh}
                      />
                    );
                    setIsOpenModal(true);
                    e.stopPropagation();
                  }}
                >
                  <img
                    src="/attache.svg"
                    alt="attachement"
                    className="w-[14px] h-[14px]"
                  />
                  <p>Connect an article</p>
                </div>
                <Button
                  gap="8px"
                  paddingX="12px"
                  paddingY="6px"
                  handleClick={() => {
                    localStorage.setItem("alpsCreateArticle", true);
                    localStorage.setItem("alpsCategoryId", categoryId);
                    window.location.pathname = "/articles";
                  }}
                >
                  <Plus size="20px" />
                  <p>Create new article</p>
                </Button>
              </div>
            </div>
            <div className="w-full p-[10px]">
              <TableOfArticle
                setIsOpenModal={setIsOpenModal}
                setModalContent={setModalContent}
                categoryId={categoryId}
                setRefresh={setLRefresh}
                refresh={lRefresh}
                category={category ? category : categoryDetails}
                searchQuery={searchQuery}
                articles={articles}
                setArticles={setArticles}
                userProfile={userProfile}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryContent;
