import React, { useState, useEffect } from "react";
import { Plus } from "heroicons-react";
import GuideTable from "./GuideTable";
import AddNewVideo from "../../components/AddNewVideo";
import Button from "../../components/Button";
import { notifyError } from "../../services/toastify";
import ErrorPopUp from "../../components/ErrorPopUp";
import Loader from "../../components/Loader/Loader";
import Loader2 from "../../components/Loader/Loader2";
import {
  createPlaylist,
  updatePlaylist,
  publishGuide,
  archiveGuide,
  getUserProfile,
} from "../../services/request";

const EachGuide = ({ playlist, refreshP, setRefreshP }) => {
  const [playlistDetails, setPlaylistDetails] = useState({
    name: `${playlist ? playlist?.name : ""}`,
    description: `${playlist ? playlist?.description : ""}`,
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [userProfile, setUserProfile] = useState("");
  const [modal, setModal] = useState(null);
  const [pLoading, setPLoading] = useState(false);
  const [aLoading, setALoading] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [playlistId, setPlaylistId] = useState(
    `${playlist ? playlist.id : ""}`
  );
  const [changing, setChanging] = useState(false);
  const [type, setType] = useState(`${playlist ? "update" : "create"}`);
  const [refresh, setRefresh] = useState(false);
  const [guides, setGuides] = useState([]);
  const [loading, setLoading] = useState(false);
  const [published, setPublished] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(
    `${playlist ? playlist.updatedAt : ""}`
  );
  // const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setPlaylistDetails({ ...playlistDetails, [name]: value });
    setChanging(!changing);
  };

  function getTimeDifference(timestamp) {
    const currentTime = new Date();
    const difference = currentTime.getTime() - timestamp.getTime();

    // Convert milliseconds to seconds
    const seconds = Math.floor(difference / 1000);

    if (seconds < 60) {
      if (seconds > 0 && seconds < 60) {
        return seconds + " seconds ago";
      } else if (seconds === 0) {
        return " just now";
      }
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return minutes + ` ${minutes > 1 ? "minutes ago" : "minute ago"}`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return hours + ` ${hours > 1 ? "hours ago" : "hour ago"}`;
    }

    const days = Math.floor(hours / 24);
    if (days < 30) {
      return days + ` ${days > 1 ? "days ago" : "day ago"}`;
    }

    const months = Math.floor(days / 30);
    if (months < 12) {
      return months + ` ${months > 1 ? "months ago" : "month ago"}`;
    }

    const years = Math.floor(months / 12);
    return years + ` ${years > 1 ? "years ago" : "year ago"}`;
  }

  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTimeAgo = getTimeDifference(new Date(updatedAt));
      setTimeAgo(newTimeAgo);
    }, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAt]);

  function generateRandomGuideName() {
    // List of adjectives and nouns to form the category name
    var adjectives = [
      "Creative",
      "Innovative",
      "Unique",
      "Dynamic",
      "Vibrant",
      "Inspiring",
      "Fresh",
    ];
    var nouns = [
      "Ideas",
      "Creations",
      "Projects",
      "Ventures",
      "Endeavors",
      "Enterprises",
    ];

    // Select a random adjective and noun
    var randomAdjective =
      adjectives[Math.floor(Math.random() * adjectives.length)];
    var randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

    // Combine them to form the category name
    var categoryName = randomAdjective + " " + randomNoun;

    return categoryName;
  }

  const createPlaylistRequest = async () => {
    let data;
    let alpsToken = localStorage.getItem("alpsToken");
    if (alpsToken && type === "create" && !playlistId) {
      setLoading(true);
      data = await createPlaylist(alpsToken, {
        name: playlistDetails.name ? playlistDetails.name : `Untitled Playlist`,
        description: playlistDetails.description,
      });
      setLoading(false);
      if (data) {
        setPlaylistId(data?.id);
        setUpdatedAt(data?.updatedAt);
      }
      setTimeAgo("Just now");
      setType("update");
    } else if (alpsToken && type === "update") {
      let data;
      setLoading(true);      
      data = await updatePlaylist(
        alpsToken,
        {
          name: playlistDetails.name
            ? playlistDetails.name
            : `Untitled Playlist`,
          description: playlistDetails.description,
        },
        playlistId
      );
      setTimeAgo("Just now");
      setUpdatedAt(data?.updatedAt);
      setLoading(false);
    }
  };

  const publishPlaylistRequest = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (playlistId && playlist?.status !== "published" && guides.length > 0) {
      setPLoading(true);
      await publishGuide(alpsToken, playlistId, setPopUp, setModal);
      setPLoading(false);
      setPublished(true);
      setRefreshP(!refreshP);
    }
    if (guides.length === 0) {
      setPopUp(true);
      setModal(
        <ErrorPopUp
          type="link"
          head="Add video first!"
          text="Please add videos to the guide before publishing"
          setPopUp={setPopUp}
        />
      );
    }
  };

  const archivePlaylistRequest = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (playlistId && playlist?.status === "published") {
      setALoading(true);
      await archiveGuide(alpsToken, playlistId);
      setPublished(false);
      setALoading(false);
      setRefreshP(!refreshP);
    }
  };

  // useEffect(() => {
  //   if (playlistDetails["name"] || playlistDetails["description"]) {
  //     createPlaylistRequest();
  //     setType("update");
  //   }
  // }, [changing]);

  const getProfile = async () => {
    const alpsToken = localStorage.getItem("alpsToken");
    let userProfile = await getUserProfile(alpsToken);
    if (userProfile) {
      setUserProfile(userProfile);      
    }
  };

  useEffect(() => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (!alpsToken) {
      window.location.pathname = "/login";
    } else {
      createPlaylistRequest();
      getProfile();
      setType("update");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isOpenModal && modalContent}
      {popUp && modal}
      <div className="w-full cflexss h-full">
        <div className="w-full flexbm text-[14px] py-[16px] px-[30px] border-b-[2px]">
          <div className="flexsm gap-[15px]">
            {playlist ? (
              <>
                <p className="capitalize">{playlist.status.toLowerCase()}</p>
                <div
                  className={`w-[8px] h-[8px] rounded-full ${
                    playlist.status === "published" && "bg-[#22C55E]"
                  } ${playlist.status === "draft" && "bg-yellow-400"} ${
                    playlist.status === "archived" && "bg-red-600"
                  }`}
                />
                <p className="text-[#7D7D7D]">
                  {updatedAt && `Last Saved ${timeAgo}`}
                </p>
              </>
            ) : (
              <>
                {published ? <p>Published</p> : <p>Archived</p>}
                <div
                  className={`w-[8px] h-[8px] rounded-full ${
                    published ? "bg-[#22C55E]" : "bg-red-600"
                  } `}
                />
                <p className="text-[#7D7D7D]">
                  {updatedAt && `Last Saved ${timeAgo}`}
                </p>
              </>
            )}
          </div>
          <div className="flexmm gap-[14px] font-[500]">
            {playlist ? (
              <>
                {playlist?.status.toLowerCase() !== "archived" &&
                  (aLoading ? (
                    <>
                      <div className="flexmm gap-[10px] cursor-pointer">
                        <Loader2 />
                        <p>Archive</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="flexmm gap-[10px] cursor-pointer"
                        onClick={() => {
                          archivePlaylistRequest();
                        }}
                      >
                        <p>Archive</p>
                      </div>
                    </>
                  ))}
              </>
            ) : (
              <>
                {published &&
                  (aLoading ? (
                    <>
                      <div className="flexmm gap-[10px] cursor-pointer">
                        <Loader2 />
                        <p>Archive</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="flexmm gap-[10px] cursor-pointer"
                        onClick={() => {
                          archivePlaylistRequest();
                        }}
                      >
                        <p>Archive</p>
                      </div>
                    </>
                  ))}
              </>
            )}
            <div
              className="flexmm px-[12px] py-[6px] gap-[8px] cursor-pointer rounded-[10px] bg-[#F5F5F5]"
              onClick={() => {
                if (
                  playlist?.status.toLowerCase() === "published" &&
                  userProfile
                ) {
                  window.open (`https://${
                    userProfile?.profile?.name
                  }.tryalps.app/guide/${playlistDetails["name"].replace(
                    / /g,
                    "-"
                  )}`, '_blank');
                }
              }}
            >
              <img
                src="/globe.svg"
                className="w-[14px] h-[14px]"
                alt="globe-icon"
              />
              <p>Preview</p>
            </div>
            {playlist ? (
              <>
                {playlist?.status.toLowerCase() !== "published" && (
                  <div className="rounded-[10px] shadow-md">
                    <Button
                      paddingX="17px"
                      paddingY="8px"
                      handleClick={() => {
                        publishPlaylistRequest();
                      }}
                    >
                      {pLoading ? (
                        <div className="flexmm gap-[10px]">
                          <Loader />
                          <p>Publish</p>
                        </div>
                      ) : (
                        <p>Publish</p>
                      )}
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <>
                {!published && (
                  <div className="rounded-[10px] shadow-md">
                    <Button
                      paddingX="17px"
                      paddingY="8px"
                      handleClick={() => {
                        publishPlaylistRequest();
                      }}
                    >
                      {pLoading ? (
                        <div className="flexmm gap-[10px]">
                          <Loader />
                          <p>Publish</p>
                        </div>
                      ) : (
                        <p>Publish</p>
                      )}
                    </Button>
                  </div>
                )}
              </>
            )}
            <Button
              paddingX="17px"
              paddingY="8px"
              handleClick={async () => {
                setType("update");
                await createPlaylistRequest();
                setRefreshP(!refreshP);
              }}
            >
              {loading ? (
                <div className="flexmm gap-[10px]">
                  <Loader />
                  <p>Update</p>
                </div>
              ) : (
                <p>Update</p>
              )}
            </Button>
          </div>
        </div>
        <div className="px-[10%] py-[40px] h-full w-full cflexss gap-[80px]">
          <div className="cflexss w-full gap-[20px]">
            <input
              value={playlistDetails["name"]}
              name="name"
              className="w-full text-[32px] outline-none border-none"
              type="text"
              placeholder="Guide Topic"
              onChange={handleChange}
            />
            <div className="w-full cflexss gap-[11px]">
              <p className="text-[12px] text-[#989898] font-[500]">
                DESCRIPTION (Optional)
              </p>
              <textarea
                value={playlistDetails["description"]}
                name="description"
                className="w-full text-[16px] outline-none border-none resize-none"
                type="text"
                placeholder="The description of the guide"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full cflexss gap-[20px]">
            <div className="w-full flexbm">
              <div className="rounded-[6px] px-[12px] py-[9px] w-[308px] flexsm gap-[10px] border-[1px]">
                <img
                  src="/search-icon.svg"
                  alt="search-icon"
                  className="w-[20px] h-[20px] cursor-pointer"
                />
                <input
                  type="search"
                  placeholder="Search videos"
                  className="outline-none w-full"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              <div className="rounded-[10px] flexmm shadow-md">
                <Button
                  gap="8px"
                  paddingX="12px"
                  paddingY="6px"
                  handleClick={() => {
                    setIsOpenModal(true);
                    if (playlistId) {
                      setModalContent(
                        <AddNewVideo
                          setIsOpenModal={setIsOpenModal}
                          playlistId={playlistId}
                          setRefresh={setRefresh}
                          refresh={refresh}
                          guide=""
                        />
                      );
                    } else {
                      notifyError(
                        "Please add guide topic and description to save the guide first"
                      );
                    }
                  }}
                >
                  <Plus size="20px" />
                  <p>Add a video</p>
                </Button>
              </div>
            </div>
            <div className="w-full p-[10px]">
              <GuideTable
                setIsOpenModal={setIsOpenModal}
                setModalContent={setModalContent}
                guides={guides}
                setGuides={setGuides}
                playlistId={playlistId}
                playlist={playlist}
                refresh={refresh}
                setRefresh={setRefresh}
                searchQuery={searchQuery}
                userProfile={userProfile}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EachGuide;
