import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize";
import Quill from "quill";

Quill.register("modules/imageResize", ImageResize);

const QuilEditor = ({ articleDetails, setArticleDetails }) => {
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
    imageResize: {},
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "align",
    "link",
    "image",
  ];

  const handleChange = (value) => {
    console.log(value);
    setArticleDetails({
      ...articleDetails,
      articleBody: value,
    });
  };

  return (
    <div className="px-[2%] w-full mb-[60px]">
      <ReactQuill
        value={articleDetails.articleBody}
        className="w-full h-[70vh] rounded-md shadow-sm"
        onChange={handleChange}
        theme="snow"
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default QuilEditor;
