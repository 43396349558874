import React, { useState, useEffect, Fragment } from "react";
import ReactPaginate from "react-paginate";
import { ArrowLeft, ArrowRight, DotsVertical, Menu } from "heroicons-react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Paginated, GetPaginatedData } from "../../atomicComponents/Pagination";
import { Popover, Transition } from "@headlessui/react";

const ArticlesTable = ({ articles, userProfile, loading }) => {
  const MenuContent = [
    {
      name: "Edit article",
    },
    {
      name: "View in help center",
    },
    // {
    //   name: "Archive",
    // },
  ];
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const PAGINATION = 5;
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);    
  };

  useEffect(() => {
    setPageCount(Paginated(articles, PAGINATION));
  }, []);

  return (
    <>
      <div className="w-full cflexss gap-[20px] mt-[20px]">
        <p className="font-medium text-[18px]">Most read articles</p>
        <table className="w-full p-0 m-0">
          {/* <thead scope="column" className="text-[16px] text-[#4D4E51]"> */}
          <thead className="text-[16px] text-[#4D4E51]">
            <tr className="w-full bg-[#F5F5F5]">
              <td className="px-[24px] py-[16px] font-[600]">
                <p>Article title</p>
              </td>
              <td className="px-[24px] py-[16px] font-[600]">
                <p>Views</p>
              </td>
              <td className="px-[24px] py-[16px] font-[600]">
                <p>Helpful</p>
              </td>
              <td className="px-[24px] py-[16px] font-[600]">
                <p>Not Helpful</p>
              </td>
            </tr>
          </thead>
          <tbody className="w-full">
            {articles?.length > 0 &&
              GetPaginatedData(currentPage, PAGINATION, articles)?.map(
                (article, index) => {
                  return (
                    <>
                      <tr
                        key={index}
                        className="cursor-pointer border-b-[1px] bg-white hover:bg-[#f5f5f5]"
                        onClick={() => {
                          window.location.pathname = `/articles/${article.id}`;
                        }}
                      >
                        <td className="px-[24px] text-[#1072E5] font-normal py-[16px]">
                          <p>{article.title}</p>
                        </td>
                        <td className="px-[24px] font-[700] py-[16px]">
                          <p>{article.views}</p>
                        </td>
                        <td className="px-[24px] font-[700] py-[16px]">
                          <p>{article.upVoteCount}</p>
                        </td>
                        <td className="px-[24px] font-[700] py-[16px] flexbm gap-[16px]">
                          <p>{article.downVoteCount}</p>
                          <Popover
                            className="relative"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            {({ open, close }) => (
                              <>
                                <Popover.Button
                                  className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-md bg-transparent px-0 py-0 text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                  <DotsVertical
                                    className={`${open ? "" : "text-opacity-70"}
                  ml-0 h-4 w-4 text-[#7d7d7d] transition duration-150 ease-in-out group-hover:text-opacity-80`}
                                    aria-hidden="true"
                                  />
                                </Popover.Button>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="absolute right-0 z-[10] mt-0 w-[192px] transform">
                                    <div className="relative py-[8px] bg-white flex-shrink shadow-xl">
                                      {MenuContent.map((content, index) => {
                                        return (
                                          <>
                                            <p
                                              key={index}
                                              className="px-[16px] py-[8px] text-[14px] font-medium hover:bg-[#f5f5f5] cursor-pointer"
                                              onClick={(e) => {
                                                if (
                                                  content.name ===
                                                  "Edit article"
                                                ) {
                                                  window.location.pathname = `/articles/${article.id}`;
                                                } else if (
                                                  content.name ===
                                                    "View in help center" &&
                                                  userProfile &&
                                                  article.status.toLowerCase() ===
                                                    "published"
                                                ) {
                                                  window.open(
                                                    `https://${
                                                      userProfile?.profile.name
                                                    }.tryalps.app/article/${article.title.replace(
                                                      / /g,
                                                      "-"
                                                    )}`,
                                                    "_blank"
                                                  );
                                                }
                                                close();
                                              }}
                                            >
                                              {content.name}
                                            </p>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        </td>
                      </tr>
                    </>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
      {loading && (
        <div className="w-full">
          <SkeletonTheme baseColor="#f5f5f5" highlightColor="#cacecf">
            {new Array(6).fill().map((x, index) => (
              <Skeleton
                key={index}
                containerClassName="w-full h-[80px] rounded-[10px]"
                className="w-full h-[100vh]"
                width={"100%"}
                height={"45px"}
                borderRadius={"5px"}
              />
            ))}
          </SkeletonTheme>
        </div>
      )}
      <div className="w-full flexem mt-[10px]">
        <ReactPaginate
          previousLabel={<ArrowLeft size="20px" />}
          previousClassName={"p-[10px] border-[2px] rounded-l-[10px]"}
          nextClassName={"p-[10px] border-[2px] rounded-r-[10px]"}
          nextLabel={<ArrowRight size="20px" />}
          breakLabel={"..."}
          breakClassName={"w-full p-[10px]"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          pageClassName={"px-[20px] py-[10px] border-[2px]"}
          onPageChange={handlePageClick}
          containerClassName={"text-[14px] lg:text-[12px] flexem"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};

export default ArticlesTable;
