import React from "react";

const ToolTip = ({ head, body, isVisible }) => {
  return (
    <>
      <div
        className={`absolute top-[100px] left-0 z-[500] cflexss gap-[6px] w-[317px] px-[14px] pt-[14px] pb-[20px] bg-[#FFFFFF] shadow-md text-black rounded-[6px] font-inter flex-shrink
    transition-opacity duration-500 ease-in-out ${
      isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
    }`}
      >
        <h1 className="text-[16px] font-semibold">{head}</h1>
        <p className="text-[14px]">{body}</p>
      </div>
    </>
  );
};

export default ToolTip;
