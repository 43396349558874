import React from "react";
import { Search } from "heroicons-react";

const HeroSection = ({ designInfo, userProfile, background }) => {
  return (
    <>
      <div
        className={`w-full mt-[70px] ${
          userProfile?.profile?.themeType === 3
            ? "pb-[50px] pt-[30px]"
            : "pb-[112px] pt-[50px]"
        } ${
          userProfile?.profile?.themeType === 2
            ? "px-[0px] mt-[0px]"
            : "px-[112px]"
        } cflexms bg-mainColor font-inter`}
        style={{
          [background ? "backgroundImage" : "background"]: background
            ? `url(${
                designInfo.heroSectionBackground
                  ? designInfo.heroSectionBackground.base64
                  : ""
              })`
            : "white",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          fontFamily: designInfo.textFont,
        }}
      >
        <div
          className={`flex flex-col justify-center ${
            userProfile?.profile?.themeType === 3
              ? "items-start w-[70%] gap-[30px]"
              : "items-center w-full gap-[48px]"
          }`}
        >
          <div
            className={`flex flex-col justify-center w-full gap-[16px] ${
              userProfile?.profile?.themeType === 2 ||
              userProfile?.profile?.themeType === 3
                ? "text-start items-start"
                : "items-center text-center"
            }`}
            style={{ color: designInfo.heroSectionTextColour }}
          >
            <h1 className="text-[52px] font-[700]">
              {designInfo?.heroSectionHeadline}
            </h1>
            <p className="text-[18px]">{designInfo?.heroSectionBodyCopy}</p>
          </div>
          <div
            className={`w-full flex items-center ${
              userProfile?.profile?.themeType === 2 ||
              userProfile?.profile?.themeType === 3
                ? "justify-start"
                : "justify-center"
            }`}
          >
            <div
              className={`pl-[24px] pr-[10px] py-[8px] ${
                userProfile?.profile?.themeType === 2 &&
                "border-black border-[2px] shadow-black shadow-xl w-full"
              } flexsm w-[800px] h-[56px] rounded-full gap-[8px] bg-white flex-shrink`}
            >
              <Search size="24px" color="#A3A3A3" className="cursor-pointer" />
              <input
                type="text"
                className="h-[22px] outline-none border-none w-[90%]"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
