import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "heroicons-react";
import NavBar from "../collections/NavBar";
import HomePage from "../helpcenter/HomePage";
import Colour from "./Colour";
import Fonts from "./Fonts";
import Header from "./Header";
import HeroSection from "./HeroSection";
import Footer from "./Footer";
import Button from "../../components/Button";
import { updateUserDesign, getUserProfile } from "../../services/request";
import Loader from "../../components/Loader/Loader";

const DesignContent = () => {
  const navigate = useNavigate();
  const [view, setView] = useState("");
  const [popUp, setPopUp] = useState(false);
  const [userProfile, setUserProfile] = useState("");
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [designInfo, setDesignInfo] = useState({
    brandColour: "#000000",
    textColour: "#F5F5F5",
    heroSectionHeadline: "",
    heroSectionBodyCopy: "",
    heroSectionBackground: "",
    heroSectionTextColour: "#F5F5F5",
    headingFont: "",
    textFont: "",
    headerLogo: "",
    headerHomePageLink: "",
    headerContactPageLink: "",
    headerLinks: "",
    footerContactUsCard: "false",
    footerAlpsLink: "true",
  });

  const saveDesignChanges = async () => {
    setLoading(true);
    console.log(
      designInfo.heroSectionBackground?.file ||
        designInfo.heroSectionBackground?.base64
    );
    await updateUserDesign(
      {
        brandColour: designInfo.brandColour,
        textColour: designInfo.textColour,
        heroSectionHeadline: designInfo.heroSectionHeadline,
        heroSectionBodyCopy: designInfo.heroSectionBodyCopy,
        heroSectionBackground: designInfo.heroSectionBackground?.file
          ? designInfo.heroSectionBackground?.file
          : designInfo.heroSectionBackground?.base64,
        heroSectionTextColour: designInfo.heroSectionTextColour,
        headingFont: designInfo.headingFont,
        textFont: designInfo.textFont,
        headerLogo: designInfo.headerLogo.file
          ? designInfo.headerLogo.file
          : designInfo.headerLogo.base64,
        headerHomePageLink: designInfo.headerHomePageLink,
        headerContactPageLink: designInfo.headerContactPageLink,
        headerLinks: JSON.stringify(designInfo.headerLinks),
        footerContactUsCard: designInfo.footerContactUsCard,
        footerAlpsLink: designInfo.footerAlpsLink,
      },
      "design",
      setModal,
      setPopUp
    );
    setLoading(false);
  };

  const getProfile = async () => {
    let alpsToken = localStorage.getItem("alpsToken");
    if (!alpsToken) {
      navigate("/login");
    }
    let info = await getUserProfile(alpsToken, setModal, setPopUp);
    setUserProfile(info);
    if (info) {
      setDesignInfo({
        brandColour:
          info?.profile?.colours?.brandColour || designInfo?.brandColour,
        textColour: info?.profile?.colours?.textColours || designInfo?.brandColour,
        heroSectionHeadline: info?.profile?.heroSection?.headline,
        heroSectionBodyCopy: info?.profile?.heroSection?.bodyCopy,
        heroSectionBackground: {
          base64: info?.profile?.heroSection?.background,
        },
        heroSectionTextColour: info?.profile?.heroSection?.textColour,
        headingFont: info?.profile?.fonts?.headingFont || "Inter",
        textFont: info?.profile?.fonts?.textFont || "Inter",
        headerLogo: { base64: info?.profile?.header?.logo },
        headerHomePageLink: info?.profile?.header?.homePageLink,
        headerContactPageLink: info?.profile?.header?.contactPageLink,
        headerLinks: info?.profile?.header?.headerLinks,
        footerContactUsCard: info?.profile?.footer
          ? info?.profile?.footer?.contactUsCard
          : "true",
        footerAlpsLink: info?.profile?.footer
          ? info?.profile?.footer?.alpsLink
          : "true",
      });
    }
  };

  useEffect(() => {
    getProfile();
  }, []);
  return (
    <>
      <NavBar active="Design" />
      {popUp && modal}
      <div className="w-full flexss h-full mt-[95px] bg-[#EEEEEE] font-inter">
        <div
          className="w-[320px] fixed z-10 left-0 cflexss gap-[24px] pb-[40px] bg-white text-primary1 flex-shrink overflow-y-auto"
          style={{ height: "calc(100vh - 95px)" }}
        >
          <div className="w-full p-[16px] flexbm border-b-[2px]">
            <button
              className="flexmm px-[12px] py-[6px] gap-[8px] rounded-[10px] text-[14px] font-medium bg-button2Color"
              onClick={() => {
                window.history.back();
              }}
            >
              <ChevronLeft />
              <p>Back</p>
            </button>
            <Button
              handleClick={() => {
                navigate("/select-theme");
              }}
            >
              <p>Select Theme</p>
            </Button>
          </div>
          <div className="w-full p-[16px] cflexss">
            <p className="text-primary2 font-semibold leading-[28px] text-[16px]">
              Help Center Customization
            </p>
            <p className="text-[14px] font-normal text-[#7C7D7E]">
              Customize fonts & colors for a personalized help center{" "}
            </p>
          </div>
          <div className="w-full cflexmm gap-[16px] text-[16px] text-black font-semibold">
            <Colour
              view={view}
              setView={setView}
              designInfo={designInfo}
              setDesignInfo={setDesignInfo}
            />
            <Fonts
              view={view}
              setView={setView}
              designInfo={designInfo}
              setDesignInfo={setDesignInfo}
            />
            <Header
              view={view}
              setView={setView}
              designInfo={designInfo}
              setDesignInfo={setDesignInfo}
              setPopUp={setPopUp}
              setModal={setModal}
            />
            <HeroSection
              view={view}
              setView={setView}
              designInfo={designInfo}
              setDesignInfo={setDesignInfo}
            />
            <Footer
              view={view}
              setView={setView}
              designInfo={designInfo}
              setDesignInfo={setDesignInfo}
              userProfile={userProfile}
              setPopUp={setPopUp}
              setModal={setModal}
            />
          </div>
        </div>
        <div
          className={`ml-[320px] h-full cflexsm gap-[25px] px-[32px]`}
          style={{ width: "calc(100% - 320px)" }}
        >
          <div className="w-full flexbm py-[16px]">
            <div className="flexss text-[14px] text-[#4D4E51]">
              <p>Preview: Home page</p>
            </div>

            <div className="flexmm gap-[20px]">
              <div
                className="flexmm px-[12px] py-[6px] gap-[8px] cursor-pointer rounded-[10px] bg-[#F5F5F5]"
                onClick={() => {
                  if (userProfile) {
                    window.open(
                      `https://${userProfile?.profile?.name}.tryalps.app`,
                      "_blank"
                    );
                  }
                }}
              >
                <img
                  src="/globe.svg"
                  className="w-[14px] h-[14px]"
                  alt="globe-icon"
                />
                <p>Preview</p>
              </div>
              <Button handleClick={saveDesignChanges}>
                {loading ? (
                  <div className="flexmm gap-[10px]">
                    <Loader /> <p>Save changes</p>
                  </div>
                ) : (
                  <p>Save changes</p>
                )}
              </Button>
            </div>
          </div>
          <div className="w-full flexmm object-content">
            <HomePage designInfo={designInfo} userProfile={userProfile} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DesignContent;
